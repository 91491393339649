import React, { useState } from 'react';
import { Download, FileSpreadsheet, Loader2, Plus } from 'lucide-react';
import { useProductStore } from '../../stores/productStore';
import { useFolderStore } from '../../stores/folderStore';
import { createImageZip, downloadZip } from '../../utils/imageCompression';
import { downloadCSV } from '../../utils/csvExport';
import { ImportFromZip } from './ImportFromZip';

export function DownloadActions() {
  const [isProcessing, setIsProcessing] = useState(false);
  const products = useProductStore(state => state.products);
  const addProduct = useProductStore(state => state.addProduct);
  const currentFolderId = useFolderStore(state => state.currentFolderId);

  const selectedProducts = products.filter(p => p.isSelected);
  const targetProducts = selectedProducts.length > 0 ? selectedProducts : products;

  const handleDownloadAll = async () => {
    if (isProcessing || targetProducts.length === 0) return;

    const allImages = targetProducts.flatMap(product => product.images);
    if (allImages.length === 0) return;

    setIsProcessing(true);
    try {
      const zipBlob = await createImageZip(allImages, targetProducts);
      const timestamp = new Date().toISOString().split('T')[0];
      downloadZip(zipBlob, `yahoo_auction_${timestamp}.zip`);
    } catch (error) {
      console.error('Failed to create ZIP:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDownloadCSV = () => {
    if (targetProducts.length === 0) return;
    downloadCSV(targetProducts, { encoding: 'utf8' });
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 mb-6">
      <div className="p-4 flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
          <button
            onClick={handleDownloadCSV}
            disabled={targetProducts.length === 0}
            className="flex items-center justify-center gap-2 px-4 py-3 h-18 rounded-md text-sm font-medium transition-colors w-full sm:w-auto text-white bg-gradient-to-r from-green-500 to-emerald-600 hover:from-green-600 hover:to-emerald-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <FileSpreadsheet className="w-5 h-5" />
            <span>CSVをダウンロード{selectedProducts.length > 0 ? ` (${selectedProducts.length}件)` : ''}</span>
          </button>

          <button
            onClick={handleDownloadAll}
            disabled={isProcessing || targetProducts.length === 0}
            className="flex items-center justify-center gap-2 px-4 py-3 h-18 rounded-md text-sm font-medium transition-colors w-full sm:w-auto text-white bg-gradient-to-r from-purple-600 to-indigo-700 hover:from-purple-700 hover:to-indigo-800 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isProcessing ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              <Download className="w-5 h-5" />
            )}
            <span>画像とCSVをダウンロード{selectedProducts.length > 0 ? ` (${selectedProducts.length}件)` : ''}</span>
          </button>
        </div>

        {currentFolderId !== null && (
          <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
            <ImportFromZip />
            <button
              onClick={() => addProduct(false)}
              className="flex items-center justify-center gap-2 px-4 py-3 h-18 rounded-md text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 transition-colors w-full sm:w-auto"
            >
              <Plus className="w-5 h-5" />
              <span>新規作成</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}