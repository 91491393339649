import React from 'react';
import type { BulkUpdates } from '../types/bulk';
import { BulkTemplateSelector } from './bulk/BulkTemplateSelector';
import { BulkTitleField } from './bulk/BulkTitleField';
import { BulkListingTypeField } from './bulk/fields/BulkListingTypeField';
import { BulkCategoryField } from './bulk/fields/BulkCategoryField';
import { BulkShippingFields } from './bulk/fields/BulkShippingFields';
import { BulkPriceField } from './bulk/fields/BulkPriceField';
import { BulkItemNumberField } from './bulk/fields/BulkItemNumberField';
import { BulkConditionField } from './bulk/fields/BulkConditionField';

interface BulkActionBarProps {
  onUpdate: (updates: BulkUpdates) => void;
  selectedCount: number;
}

export function BulkActionBar({ onUpdate, selectedCount }: BulkActionBarProps) {
  return (
    <div className="p-4 space-y-6">
      <div className="text-sm text-gray-600 mb-4">
        選択中のアイテム: {selectedCount}件
      </div>

      <BulkTemplateSelector onUpdate={onUpdate} />
      <BulkTitleField onUpdate={onUpdate} />
      <BulkListingTypeField onUpdate={onUpdate} />
      <BulkCategoryField onUpdate={onUpdate} />
      <BulkShippingFields onUpdate={onUpdate} />
      <BulkPriceField onUpdate={onUpdate} />
      <BulkItemNumberField onUpdate={onUpdate} />
      <BulkConditionField onUpdate={onUpdate} />
    </div>
  );
}