import { Product } from '../../types';
import { PREDEFINED_SHIPPING_METHODS } from '../shipping/constants';

interface CSVParseResult {
  success: boolean;
  products: Product[];
  errors: string[];
}

export async function parseCSV(csvContent: string): Promise<CSVParseResult> {
  const lines = csvContent.split('\n');
  const products: Product[] = [];
  const errors: string[] = [];

  // Skip header row if present
  const startIndex = lines[0].includes('カテゴリ') ? 1 : 0;

  for (let i = startIndex; i < lines.length; i++) {
    const line = lines[i].trim();
    if (!line) continue;

    try {
      const fields = parseCSVLine(line);
      const product = createProductFromFields(fields);
      products.push(product);
    } catch (error) {
      errors.push(`行 ${i + 1}: ${error instanceof Error ? error.message : '不明なエラー'}`);
    }
  }

  return {
    success: errors.length === 0,
    products,
    errors
  };
}

function parseCSVLine(line: string): string[] {
  const fields: string[] = [];
  let field = '';
  let inQuotes = false;

  for (let i = 0; i < line.length; i++) {
    const char = line[i];

    if (char === '"') {
      if (inQuotes && line[i + 1] === '"') {
        field += '"';
        i++;
      } else {
        inQuotes = !inQuotes;
      }
    } else if (char === ',' && !inQuotes) {
      fields.push(field);
      field = '';
    } else {
      field += char;
    }
  }

  fields.push(field);
  return fields;
}

function createProductFromFields(fields: string[]): Product {
  const product: Partial<Product> = {
    title: fields[1] || '',
    description: fields[2] || '',
    startPrice: parseInt(fields[3]) || 0,
    buyItNowPrice: fields[4] ? parseInt(fields[4]) : undefined,
    quantity: parseInt(fields[5]) || 1,
    auctionDuration: parseInt(fields[6]) || 7,
    endTime: fields[7] || '22',
    images: [],
    shippingLocation: fields[20] || '',
    shippingResponsibility: fields[22] === '出品者' ? 'seller' : 'buyer',
    condition: parseCondition(fields[27]),
    returnsAccepted: fields[29] === '返品可',
    ratingRestriction: fields[31] === 'はい',
    badRatingRestriction: fields[32] === 'はい',
    bidderAuthentication: fields[33] === 'はい',
    autoExtensionByBid: fields[34] === 'はい',
    autoExtension: fields[38] === 'はい',
    autoExtensionRate: parseInt(fields[39]) || 5,
    autoExtensionCount: parseInt(fields[37]) || 1,
    shippingLater: fields[42] === 'いいえ',
    shippingCOD: false,
    nekoposu: fields[45] === 'はい',
    nekoCompact: fields[46] === 'はい',
    nekoEazy: fields[47] === 'はい',
    yuPacket: fields[48] === 'はい',
    yuPack: fields[49] === 'はい',
    yuPacketMini: fields[50] === 'はい',
    yuPacketPlus: fields[51] === 'はい',
    customShippingMethods: parseCustomShippingMethods(fields.slice(53, 103)),
    listingType: 'auction',
    isFeatured: false,
    featuredMinPrice: 0,
    recommendedCollection: false,
    recommendedCollectionRate: 0,
    itemNumber: '',
    itemNumber2: '',
    itemNumberPrefix: false,
    itemNumberSuffix: false,
    itemNumberSerial: false,
    memo: '',
    memoPrefix: false,
    memoSuffix: false,
    memoSerial: false
  };

  return product as Product;
}

function parseCondition(value: string): string {
  const conditionMap: { [key: string]: string } = {
    '未使用': 'new',
    '未使用に近い': 'like-new',
    '目立った傷や汚れなし': 'good',
    'やや傷や汚れあり': 'fair',
    '傷や汚れあり': 'poor',
    '全体的に状態が悪い': 'bad'
  };
  return conditionMap[value] || '';
}

function parseCustomShippingMethods(fields: string[]): any[] {
  const methods = [];
  
  // Process 10 sets of shipping method fields (5 fields per method)
  for (let i = 0; i < 50; i += 5) {
    const name = fields[i];
    if (!name) continue;

    // Find predefined method or create custom
    const predefinedMethod = PREDEFINED_SHIPPING_METHODS.find(m => m.label === name);
    const method = {
      name: predefinedMethod?.value || 'other',
      carrierName: predefinedMethod ? undefined : name,
      price: fields[i + 1],
      hokkaido: fields[i + 2],
      okinawa: fields[i + 3],
      island: fields[i + 4]
    };

    if (method.name || method.carrierName) {
      methods.push(method);
    }
  }

  return methods;
}