import React, { useState } from 'react';
import { Truck, Clock, MapPin } from 'lucide-react';
import { prefectures } from '../../../utils/prefectures';
import type { BulkUpdates } from '../../../types/bulk';

interface BulkShippingFieldsProps {
  onUpdate: (updates: BulkUpdates) => void;
}

export function BulkShippingFields({ onUpdate }: BulkShippingFieldsProps) {
  const [shippingResponsibility, setShippingResponsibility] = useState('');
  const [shippingTime, setShippingTime] = useState('');
  const [shippingLocation, setShippingLocation] = useState('');

  const handleResponsibilityApply = () => {
    if (shippingResponsibility) {
      onUpdate({ shippingResponsibility });
      setShippingResponsibility('');
    }
  };

  const handleTimeApply = () => {
    if (shippingTime) {
      onUpdate({ shippingTime });
      setShippingTime('');
    }
  };

  const handleLocationApply = () => {
    if (shippingLocation) {
      onUpdate({ shippingLocation });
      setShippingLocation('');
    }
  };

  return (
    <div className="space-y-4">
      {/* Shipping Responsibility */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <Truck className="w-5 h-5 text-gray-500" />
          <span className="text-sm font-medium text-gray-700">送料負担を変更</span>
        </div>
        <div className="flex items-center gap-4">
          <select
            value={shippingResponsibility}
            onChange={(e) => setShippingResponsibility(e.target.value)}
            className="w-48 rounded-md border-gray-300 bg-gray-50 text-sm"
          >
            <option value="">選択してください</option>
            <option value="buyer">落札者</option>
            <option value="seller">出品者</option>
          </select>
          <button
            onClick={handleResponsibilityApply}
            disabled={!shippingResponsibility}
            className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
              shippingResponsibility
                ? 'bg-blue-600 text-white hover:bg-blue-700'
                : 'bg-gray-100 text-gray-400 cursor-not-allowed'
            }`}
          >
            変更
          </button>
        </div>
      </div>

      {/* Shipping Time */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <Clock className="w-5 h-5 text-gray-500" />
          <span className="text-sm font-medium text-gray-700">発送までの日数を変更</span>
        </div>
        <div className="flex items-center gap-4">
          <select
            value={shippingTime}
            onChange={(e) => setShippingTime(e.target.value)}
            className="w-48 rounded-md border-gray-300 bg-gray-50 text-sm"
          >
            <option value="">選択してください</option>
            <option value="1-2">1～2日</option>
            <option value="2-3">2～3日</option>
            <option value="3-7">3～7日</option>
          </select>
          <button
            onClick={handleTimeApply}
            disabled={!shippingTime}
            className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
              shippingTime
                ? 'bg-blue-600 text-white hover:bg-blue-700'
                : 'bg-gray-100 text-gray-400 cursor-not-allowed'
            }`}
          >
            変更
          </button>
        </div>
      </div>

      {/* Shipping Location */}
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <MapPin className="w-5 h-5 text-gray-500" />
          <span className="text-sm font-medium text-gray-700">発送元の地域を変更</span>
        </div>
        <div className="flex items-center gap-4">
          <select
            value={shippingLocation}
            onChange={(e) => setShippingLocation(e.target.value)}
            className="w-48 rounded-md border-gray-300 bg-gray-50 text-sm"
          >
            <option value="">選択してください</option>
            {prefectures.map(prefecture => (
              <option key={prefecture} value={prefecture}>
                {prefecture}
              </option>
            ))}
          </select>
          <button
            onClick={handleLocationApply}
            disabled={!shippingLocation}
            className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
              shippingLocation
                ? 'bg-blue-600 text-white hover:bg-blue-700'
                : 'bg-gray-100 text-gray-400 cursor-not-allowed'
            }`}
          >
            変更
          </button>
        </div>
      </div>
    </div>
  );
}