import JSZip from 'jszip';
import { Product } from '../../types';
import { parseCSV } from './csvParser';
import { processImportedImages, matchImagesWithProducts, ProcessedImage } from './imageProcessor';

export interface ImportResult {
  success: boolean;
  products: Product[];
  errors: string[];
}

export async function importFromZip(file: File): Promise<ImportResult> {
  try {
    const zip = await JSZip.loadAsync(file);
    const errors: string[] = [];
    let csvContent = '';
    const imageFiles: File[] = [];

    // Extract files from zip
    for (const [filename, zipEntry] of Object.entries(zip.files)) {
      if (filename.endsWith('.csv')) {
        const content = await zipEntry.async('string');
        csvContent = content;
      } else if (filename.match(/\.(jpg|jpeg|png)$/i)) {
        const blob = await zipEntry.async('blob');
        imageFiles.push(new File([blob], filename, { type: `image/${filename.split('.').pop()}` }));
      }
    }

    if (!csvContent) {
      return {
        success: false,
        products: [],
        errors: ['CSVファイルが見つかりませんでした']
      };
    }

    // Process CSV and images
    const { products, errors: csvErrors } = await parseCSV(csvContent);
    const processedImages = await processImportedImages(imageFiles);

    // Match images with products
    products.forEach((product, index) => {
      const imageFilenames = Array.from({ length: 10 }, (_, i) => 
        `${String(index * 10 + i + 1).padStart(6, '0')}.jpg`
      );
      product.images = matchImagesWithProducts(processedImages, imageFilenames)
        .filter(Boolean);
    });

    return {
      success: errors.length === 0 && csvErrors.length === 0,
      products,
      errors: [...errors, ...csvErrors]
    };
  } catch (error) {
    return {
      success: false,
      products: [],
      errors: [(error as Error).message || 'ZIPファイルの読み込みに失敗しました']
    };
  }
}