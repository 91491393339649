import React, { useState, useRef } from 'react';
import { Upload, AlertCircle, Loader2 } from 'lucide-react';
import { useProductStore } from '../../stores/productStore';
import { importFromZip } from '../../utils/import/zipImporter';
import { LoadingOverlay } from '../common/LoadingOverlay';

export function ImportFromZip() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const addProduct = useProductStore(state => state.addProduct);
  const updateProduct = useProductStore(state => state.updateProduct);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setIsProcessing(true);
    setError(null);

    try {
      const { success, products, errors } = await importFromZip(file);

      if (!success) {
        setError(errors.join('\n'));
        return;
      }

      // Create products one by one
      for (const productData of products) {
        const newProduct = await addProduct();
        await updateProduct({
          ...newProduct,
          ...productData
        });
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : '不明なエラーが発生しました');
    } finally {
      setIsProcessing(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div>
      <button
        onClick={() => fileInputRef.current?.click()}
        disabled={isProcessing}
        className={`w-full flex items-center justify-center gap-2 px-4 py-3 h-12 rounded-md text-sm font-medium transition-colors ${
          isProcessing
            ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
            : 'text-white bg-gradient-to-r from-green-500 to-emerald-600 hover:from-green-600 hover:to-emerald-700'
        }`}
      >
        <input
          ref={fileInputRef}
          type="file"
          accept=".zip"
          className="hidden"
          onChange={handleFileSelect}
          onClick={e => {
            (e.target as HTMLInputElement).value = '';
          }}
        />
        {isProcessing ? (
          <Loader2 className="w-5 h-5 animate-spin" />
        ) : (
          <Upload className="w-5 h-5" />
        )}
        <span>画像とCSVを読み込み</span>
      </button>

      {error && (
        <div className="mt-2 p-3 bg-red-50 text-red-600 text-sm rounded-md flex items-start gap-2">
          <AlertCircle className="w-5 h-5 flex-shrink-0 mt-0.5" />
          <div className="whitespace-pre-wrap">{error}</div>
        </div>
      )}

      {isProcessing && (
        <LoadingOverlay 
          message="商品データを読み込んでいます。今しばらくお待ちください..."
        />
      )}
    </div>
  );
}