import React from 'react';
import { useTemplateStore } from '../../stores/templateStore';
import type { BulkUpdates } from '../../types/bulk';

interface BulkTemplateSelectorProps {
  onUpdate: (updates: BulkUpdates) => void;
}

export function BulkTemplateSelector({ onUpdate }: BulkTemplateSelectorProps) {
  const { templates, activeTemplateId, setActiveTemplate } = useTemplateStore();

  const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const templateId = e.target.value;
    setActiveTemplate(templateId || null);
  };

  const handleApplyTemplate = () => {
    if (!activeTemplateId) return;
    
    const template = templates.find(t => t.id === activeTemplateId);
    if (!template) return;

    const updates: BulkUpdates = {
      ...template.fields,
      customShippingMethods: [...template.fields.customShippingMethods]
    };

    onUpdate(updates);
  };

  if (templates.length === 0) {
    return null;
  }

  return (
    <div className="flex items-center gap-4 p-4 bg-gradient-to-r from-indigo-50 via-purple-50 to-pink-50 rounded-lg">
      <select
        value={activeTemplateId || ''}
        onChange={handleTemplateChange}
        className="flex-1 h-10 px-3 rounded-md border-gray-300 bg-white"
      >
        <option value="">テンプレートを選択...</option>
        {templates.map(template => (
          <option key={template.id} value={template.id}>
            {template.name}
          </option>
        ))}
      </select>
      <button
        onClick={handleApplyTemplate}
        disabled={!activeTemplateId}
        className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
      >
        テンプレートを適用
      </button>
    </div>
  );
}