import { create } from 'zustand';
import type { Product } from '../types';
import type { BulkUpdates } from '../types/bulk';
import {
  createProduct,
  updateProductById,
  deleteProductById,
  getProductsByUser,
  getMaxSerialNumberByUser,
  deleteProductImages
} from '../db';
import { useFolderStore } from './folderStore';
import { useAuthStore } from './authStore';
import { useTemplateStore } from './templateStore';

interface ProductState {
  products: Product[];
  lastSerialNumber: number;
  isLoading: boolean;
  loadProducts: () => Promise<void>;
  addProduct: (useTemplate?: boolean) => Promise<Product>;
  updateProduct: (product: Product) => Promise<void>;
  deleteProduct: (productId: number) => Promise<void>;
  deleteMultipleProducts: (productIds: number[]) => Promise<void>;
  updateMultipleProducts: (updates: BulkUpdates, selectedIds: number[]) => Promise<void>;
  toggleProductSelection: (productId: number) => void;
  selectAllProducts: (selected: boolean) => void;
}

export const useProductStore = create<ProductState>((set, get) => ({
  products: [],
  lastSerialNumber: 0,
  isLoading: false,

  loadProducts: async () => {
    const user = useAuthStore.getState().user;
    if (!user) return;

    set({ isLoading: true });
    try {
      const products = await getProductsByUser(user.email);
      const lastSerialNumber = await getMaxSerialNumberByUser(user.email);
      set({ products, lastSerialNumber, isLoading: false });
    } catch (error) {
      console.error('Failed to load products:', error);
      set({ isLoading: false });
    }
  },

  addProduct: async (useTemplate = false) => {
    const user = useAuthStore.getState().user;
    if (!user) throw new Error('User not authenticated');

    const { lastSerialNumber } = get();
    const currentFolderId = useFolderStore.getState().currentFolderId;
    const newSerialNumber = lastSerialNumber + 1;
    const templateStore = useTemplateStore.getState();
    const activeTemplateId = templateStore.activeTemplateId;

    let baseProduct: Omit<Product, 'id'> = {
      userId: user.email,
      folderId: currentFolderId,
      serialNumber: newSerialNumber,
      title: '',
      category: '',
      startPrice: 1000,
      buyItNowPrice: undefined,
      quantity: 1,
      auctionDuration: 7,
      endTime: '22',
      description: '',
      condition: '',
      itemNumber: '',
      itemNumber2: '',
      itemNumberPrefix: false,
      itemNumberSuffix: false,
      itemNumberSerial: false,
      memo: '',
      memoPrefix: false,
      memoSuffix: false,
      memoSerial: false,
      autoExtension: false,
      autoExtensionRate: 5,
      autoExtensionCount: 1,
      autoExtensionByBid: true,
      images: [],
      shippingUnspecified: false,
      shippingLater: false,
      shippingCOD: false,
      shippingResponsibility: 'buyer',
      shippingTime: '2-3',
      shippingLocation: '',
      nekoposu: false,
      nekoCompact: false,
      nekoEazy: false,
      nekoEazySize: '',
      nekoEazyWeight: '',
      yuPacketMini: false,
      yuPacket: false,
      yuPacketPlus: false,
      yuPack: false,
      yuPackSize: '',
      yuPackWeight: '',
      customShippingMethods: [],
      listingType: 'auction',
      ratingRestriction: true,
      badRatingRestriction: true,
      bidderAuthentication: false,
      returnsAccepted: false,
      isFeatured: false,
      featuredMinPrice: 0,
      featuredMaxPrice: 0,
      recommendedCollection: false,
      recommendedCollectionRate: 0,
      isSelected: false
    };

    if (useTemplate && activeTemplateId) {
      const template = templateStore.getTemplateById(activeTemplateId);
      if (template) {
        baseProduct = {
          ...baseProduct,
          ...template.fields,
          customShippingMethods: [...template.fields.customShippingMethods]
        };
      }
    }

    try {
      const newId = await createProduct(baseProduct, user.email);
      const productWithId = { ...baseProduct, id: newId };

      set(state => ({
        products: [productWithId, ...state.products],
        lastSerialNumber: newSerialNumber
      }));

      return productWithId;
    } catch (error) {
      console.error('Failed to create product:', error);
      throw error;
    }
  },

  updateProduct: async (product: Product) => {
    try {
      await updateProductById(product.id, product);
      set(state => ({
        products: state.products.map(p =>
          p.id === product.id ? product : p
        )
      }));
    } catch (error) {
      console.error('Failed to update product:', error);
      throw error;
    }
  },

  deleteProduct: async (productId: number) => {
    try {
      await deleteProductImages(productId);
      await deleteProductById(productId);
      set(state => ({
        products: state.products.filter(p => p.id !== productId)
      }));
    } catch (error) {
      console.error('Failed to delete product:', error);
      throw error;
    }
  },

  deleteMultipleProducts: async (productIds: number[]) => {
    try {
      await Promise.all(productIds.map(async (id) => {
        await deleteProductImages(id);
        await deleteProductById(id);
      }));
      set(state => ({
        products: state.products.filter(p => !productIds.includes(p.id))
      }));
    } catch (error) {
      console.error('Failed to delete multiple products:', error);
      throw error;
    }
  },

  updateMultipleProducts: async (updates: BulkUpdates, selectedIds: number[]) => {
    const { products } = get();
    const updatedProducts = products.map(product => {
      if (!selectedIds.includes(product.id)) {
        return product;
      }

      const updated = { ...product };
      
      Object.entries(updates).forEach(([key, value]) => {
        if (value !== undefined) {
          (updated as any)[key] = value;
        }
      });

      if (updates.listingType === 'fixed') {
        updated.buyItNowPrice = undefined;
      }

      if (updates.shippingResponsibility === 'seller') {
        updated.shippingLater = false;
        updated.shippingCOD = false;
      }

      return updated;
    });

    try {
      await Promise.all(
        updatedProducts
          .filter(product => selectedIds.includes(product.id))
          .map(product => updateProductById(product.id, product))
      );

      set({ products: updatedProducts });
    } catch (error) {
      console.error('Failed to update multiple products:', error);
      throw error;
    }
  },

  toggleProductSelection: (productId: number) => {
    set(state => ({
      products: state.products.map(product =>
        product.id === productId
          ? { ...product, isSelected: !product.isSelected }
          : product
      )
    }));
  },

  selectAllProducts: (selected: boolean) => {
    const currentFolderId = useFolderStore.getState().currentFolderId;
    
    set(state => ({
      products: state.products.map(product => ({
        ...product,
        isSelected: currentFolderId === null 
          ? selected 
          : product.folderId === currentFolderId 
            ? selected 
            : product.isSelected
      }))
    }));
  }
}));