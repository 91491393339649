import React, { useState } from 'react';
import { Hash } from 'lucide-react';
import type { BulkUpdates } from '../../../types/bulk';

interface BulkItemNumberFieldProps {
  onUpdate: (updates: BulkUpdates) => void;
}

export function BulkItemNumberField({ onUpdate }: BulkItemNumberFieldProps) {
  const [itemNumber, setItemNumber] = useState('');
  const [itemNumber2, setItemNumber2] = useState('');
  const [itemNumberPrefix, setItemNumberPrefix] = useState(false);
  const [itemNumberSuffix, setItemNumberSuffix] = useState(false);
  const [itemNumberSerial, setItemNumberSerial] = useState(false);
  const [memoPrefix, setMemoPrefix] = useState(false);
  const [memoSuffix, setMemoSuffix] = useState(false);
  const [memoSerial, setMemoSerial] = useState(false);

  const handleApply = () => {
    const updates: BulkUpdates = {};
    
    if (itemNumber) updates.itemNumber = itemNumber;
    if (itemNumber2) updates.itemNumber2 = itemNumber2;
    if (itemNumberPrefix) updates.itemNumberPrefix = itemNumberPrefix;
    if (itemNumberSuffix) updates.itemNumberSuffix = itemNumberSuffix;
    if (itemNumberSerial) updates.itemNumberSerial = itemNumberSerial;
    if (memoPrefix) updates.memoPrefix = memoPrefix;
    if (memoSuffix) updates.memoSuffix = memoSuffix;
    if (memoSerial) updates.memoSerial = memoSerial;

    if (Object.keys(updates).length > 0) {
      onUpdate(updates);
      setItemNumber('');
      setItemNumber2('');
      setItemNumberPrefix(false);
      setItemNumberSuffix(false);
      setItemNumberSerial(false);
      setMemoPrefix(false);
      setMemoSuffix(false);
      setMemoSerial(false);
    }
  };

  return (
    <div className="flex items-center gap-4">
      <div className="flex items-center gap-2">
        <Hash className="w-5 h-5 text-gray-500" />
        <span className="text-sm font-medium text-gray-700">管理番号を変更</span>
      </div>
      <div className="space-y-3">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <input
              type="text"
              value={itemNumber}
              onChange={(e) => setItemNumber(e.target.value)}
              placeholder="管理番号1"
              className="w-32 rounded-md border-gray-300 bg-gray-50 text-sm"
            />
            <div className="flex gap-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={itemNumberPrefix}
                  onChange={(e) => {
                    setItemNumberPrefix(e.target.checked);
                    if (e.target.checked) setItemNumberSuffix(false);
                  }}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-600">文頭</span>
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={itemNumberSuffix}
                  onChange={(e) => {
                    setItemNumberSuffix(e.target.checked);
                    if (e.target.checked) setItemNumberPrefix(false);
                  }}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-600">文尾</span>
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={itemNumberSerial}
                  onChange={(e) => setItemNumberSerial(e.target.checked)}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-600">連番</span>
              </label>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <input
              type="text"
              value={itemNumber2}
              onChange={(e) => setItemNumber2(e.target.value)}
              placeholder="管理番号2"
              className="w-32 rounded-md border-gray-300 bg-gray-50 text-sm"
            />
            <div className="flex gap-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={memoPrefix}
                  onChange={(e) => {
                    setMemoPrefix(e.target.checked);
                    if (e.target.checked) setMemoSuffix(false);
                  }}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-600">文頭</span>
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={memoSuffix}
                  onChange={(e) => {
                    setMemoSuffix(e.target.checked);
                    if (e.target.checked) setMemoPrefix(false);
                  }}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-600">文尾</span>
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={memoSerial}
                  onChange={(e) => setMemoSerial(e.target.checked)}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-600">連番</span>
              </label>
            </div>
            <button
              onClick={handleApply}
              disabled={!itemNumber && !itemNumber2}
              className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
                itemNumber || itemNumber2
                  ? 'bg-blue-600 text-white hover:bg-blue-700'
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'
              }`}
            >
              変更
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}