import React from 'react';

interface AutoExtensionSettingsProps {
  autoExtension: boolean;
  autoExtensionRate: number;
  autoExtensionCount: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

export const AutoExtensionSettings: React.FC<AutoExtensionSettingsProps> = ({
  autoExtension,
  autoExtensionRate,
  autoExtensionCount,
  onChange
}) => {
  return (
    <div className="bg-white rounded-lg p-4 border border-gray-200">
      <h4 className="font-medium mb-4">自動再出品設定</h4>
      <div className="space-y-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            name="autoExtension"
            checked={autoExtension}
            onChange={onChange}
            className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          <span className="ml-2 text-sm text-gray-700">自動再出品を有効にする</span>
        </label>

        {autoExtension && (
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">値下げ率</label>
              <select
                name="autoExtensionRate"
                value={autoExtensionRate}
                onChange={onChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
              >
                <option value="0">値下げしない</option>
                <option value="5">5%</option>
                <option value="10">10%</option>
                <option value="15">15%</option>
                <option value="20">20%</option>
                <option value="25">25%</option>
                <option value="30">30%</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">再出品回数</label>
              <select
                name="autoExtensionCount"
                value={autoExtensionCount}
                onChange={onChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-50"
              >
                {[1, 2, 3].map(count => (
                  <option key={count} value={count}>{count}回</option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};