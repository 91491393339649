import React, { useState } from 'react';
import { Gavel } from 'lucide-react';
import type { BulkUpdates } from '../../../types/bulk';

interface BulkPriceFieldProps {
  onUpdate: (updates: BulkUpdates) => void;
}

export function BulkPriceField({ onUpdate }: BulkPriceFieldProps) {
  const [startPrice, setStartPrice] = useState('');
  const [buyItNowPrice, setBuyItNowPrice] = useState('');

  const handleApply = () => {
    const updates: BulkUpdates = {};
    if (startPrice) {
      updates.startPrice = Number(startPrice);
    }
    if (buyItNowPrice) {
      updates.buyItNowPrice = Number(buyItNowPrice);
    }
    if (Object.keys(updates).length > 0) {
      onUpdate(updates);
      setStartPrice('');
      setBuyItNowPrice('');
    }
  };

  return (
    <div className="flex items-center gap-4">
      <div className="flex items-center gap-2">
        <Gavel className="w-5 h-5 text-gray-500" />
        <span className="text-sm font-medium text-gray-700">価格を変更</span>
      </div>
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <input
            type="number"
            value={startPrice}
            onChange={(e) => setStartPrice(e.target.value)}
            placeholder="開始価格"
            className="w-32 rounded-md border-gray-300 bg-gray-50 text-sm"
          />
          <span className="text-sm text-gray-500">円</span>
        </div>
        <div className="flex items-center gap-2">
          <input
            type="number"
            value={buyItNowPrice}
            onChange={(e) => setBuyItNowPrice(e.target.value)}
            placeholder="即決価格"
            className="w-32 rounded-md border-gray-300 bg-gray-50 text-sm"
          />
          <span className="text-sm text-gray-500">円</span>
        </div>
        <button
          onClick={handleApply}
          disabled={!startPrice && !buyItNowPrice}
          className={`px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
            startPrice || buyItNowPrice
              ? 'bg-blue-600 text-white hover:bg-blue-700'
              : 'bg-gray-100 text-gray-400 cursor-not-allowed'
          }`}
        >
          変更
        </button>
      </div>
    </div>
  );
}