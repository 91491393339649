export interface ProcessedImage {
  filename: string;
  data: string;
}

export async function processImportedImages(files: File[]): Promise<ProcessedImage[]> {
  const processedImages: ProcessedImage[] = [];

  for (const file of files) {
    try {
      const data = await readImageFile(file);
      processedImages.push({
        filename: file.name,
        data
      });
    } catch (error) {
      console.error(`Failed to process image ${file.name}:`, error);
    }
  }

  return processedImages;
}

function readImageFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(new Error(`Failed to read file: ${file.name}`));
    reader.readAsDataURL(file);
  });
}

export function matchImagesWithProducts(
  images: ProcessedImage[],
  imageFilenames: string[]
): string[] {
  return imageFilenames.map(filename => {
    const image = images.find(img => img.filename === filename);
    return image ? image.data : '';
  });
}